@import './variables';
@import './mixins';

* {
  font-family: $font-family;
}

body,
html {
  background-color: white;
  user-select: none;
  padding: 0;
  margin: 0;
}

#root {
  overflow: scroll;

  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
}

.answers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.quiz-selection {
    margin-top: 1rem;
    flex-direction: row;

    img {
      filter: brightness(0%);
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    button {
      min-height: 80px;
      max-width: 230px;
      @include buttonStyles(transparent, $gray);
    }
  }

  button {
    @include buttonStyles(transparent, $gray);
  }
}

.question {
  text-align: center;
  font-size: 1.5rem;
  color: black;

  .count {
    display: block;
    font-size: 14px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.score-section {
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    @include buttonStyles(transparent, $gray);
  }

  img {
    margin-bottom: 10px;
    max-width: 100px;
  }

  p {
    max-width: 500px;
    text-align: center;
    color: #808080;
    margin: 5px;
    font-weight: 300;
    font-size: 15px;
  }
}

.info,
.app {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin: 1rem 0 1rem 0;

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
  }

  p {
    color: #808080;
    margin: 5px;
    font-weight: 300;
  }
}

.logo {
  max-width: 100px;
}

.feedback-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .material-symbols-outlined {
    font-size: 5rem;
    margin: 0;
  }

  h2 {
    color: #808080;
    font-weight: 200;
    font-size: 13px;
  }
}

.legal {
  font-size: 10px;
  color: $border-light;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  p {
    color: $border-light;
    font-weight: 300;
    margin: 0;
  }

  a,
  button {
    background-color: transparent;
    cursor: pointer;
    border: 0;
    font-weight: 300;
    color: $border-light;
    text-decoration: none;
    border-right: 1px solid $border-light;
    margin-right: 5px;
    padding-right: 5px;
    font-size: 10px;


    &:nth-child(3) {
      border: none;
      margin: 0;
      padding: 0;
    }

    &:hover {
      text-decoration: underline;
    }

  }

  span {
    font-size: 8px;
    letter-spacing: 0px;
    line-height: 10px;
    font-weight: 100;
    text-align: justify;
    display: block;
  }
}

.material-symbols-outlined {
  color: black;
}